<template>
  <v-app>
    <v-snackbar
      v-model="showAlert"
      :top="true"
      :right="true"
      :color="alertColor"
      timeout="5000"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showAlert = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
      <router-view />

  </v-app>
</template>

<script>
import config from './configs'
import httpClient from "@/plugins/axios";
import store from './store';
/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },
  data(){
    return {
      showAlert: false,
      alertMessage: '',
      alertColor:'success'
    }
  },
  mounted(){
    let vm = this;
    this.saveSuccess = (data) =>{
      vm.alertMessage = data.message;
      vm.showAlert = true;
      vm.alertColor = 'success';
    };
    this.saveFail = (data) => {
      vm.alertMessage = data.message;
      vm.showAlert = true;
      vm.alertColor = 'error';
    };

    this.$root.$on("saveSuccess", this.saveSuccess);
    this.$root.$on("saveFailed", this.saveFail);


  },
  beforeDestroy(){
    this.$root.$off("saveSuccess", this.saveSuccess);
    this.$root.$off("saveFailed", this.saveFail);
  }
}
</script>
