import configs from '../../configs'
import Vuetify from "@/plugins/vuetify";
import httpClient from '@/plugins/axios';


const { product, theme  } = configs
const { isRTL, globalTheme } = theme

// state initial values
const state = {
  product,
  // app theme dark or light
  globalTheme,
  // themes and layout configurations
  isRTL,
  profile: {},
  organizations:[],
  categories: [],
  service: [],
  studentToken: '',
}

const mutations = {
  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    Vuetify.framework.theme.dark = theme === 'dark'
    state.globalTheme = theme
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  setProfile: (state, profile) => {
    state.profile = profile
  },
  setOrganizations: (state, organizations) => {
    state.organizations = organizations;
  },
  setCategories: (state, categories) => {
    state.categories = categories;
  },
  setService: (state, service) => {
    state.service = service;
  },
  addService: (state, item) => {
    state.service.push(item);
  },
  setStudentToken: (state, token) => {
    state.studentToken = token;
  }
}


const getters = {
  isAuthenticated: state => {return state.profile.identifier !== undefined;},
  isAdmin: state => state.profile.role === 'Admin',
  getUser: state => {return state.profile;},
  studentToken: state => {return state.studentToken;}
}

const actions = {
  login: ({ commit, dispatch }, credentials) => {
    const vm = this;
     return httpClient.post('/account/login', credentials, {withCredentials:true})
      .then(res => {

        if(res.data.success){
          commit('setProfile', res.data)
          dispatch('getDomainData');
          localStorage.setItem("isAuthenticated", 'true');
        }


        return res.data;
    })
  },
  logout: ({ commit }) => {

    return httpClient.post('/account/logout')
      .then(() => {
        console.log('store logout')
      commit('setProfile', {})
      localStorage.removeItem("isAuthenticated");

    })
  },
  getDomainData: ({commit}) => {
    httpClient.get('/domainData/getAll')
      .then(function(response){

        commit('setCategories', response.data.categories);
        commit('setOrganizations', response.data.organizations);
      })
      .catch(function(error){
        console.log(error);
      });
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
