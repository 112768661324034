import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

// Global vuex
import AppModule from './app'
import ValidationModule from './validation'
Vue.use(Vuex)


/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    validation: ValidationModule
  },
  plugins: [createPersistedState({
    key: 'state',
    storage: window.localStorage
  })]
})

export default store
