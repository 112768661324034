import configs from '../../configs'
import Vuetify from "@/plugins/vuetify";
import httpClient from '@/plugins/axios';


// state initial values
const state = {

}

const mutations = {

}

const getters = {

}

const actions = {
  IsEmailUnique: ({ commit, dispatch }, email) => {
    const vm = this;
    return httpClient.post('/account/validate-email', email, {withCredentials:true})
      .then(res => {
        return !res.data.exists;
      })
  },

}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
