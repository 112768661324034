import Vue from 'vue'
import App from './App.vue'
import VueLuxon from "vue-luxon";
import VNumeric from 'vuetify-numeric/vuetify-numeric.umd.min'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import DisableAutocomplete from 'vue-disable-autocomplete';
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import VueApexCharts from 'vue-apexcharts'
import VueHtmlToPaper from 'vue-html-to-paper';


const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
    'top=0',
    'left=0'
  ],
  styles: [
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
    'https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css',
    'https://apexcharts.com/wp-content/cache/autoptimize/css/autoptimize_9c2eed08b0ab8b5c5473d9f5c71230a1.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options);

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vue-head'
// import './plugins/vue-gtag'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

Vue.use(VueLuxon, {
  templates: {},
  input: {
    zone: "America/Chicago",
    format: "iso"
  },
  output: {
    zone: "local",
    format: "short",
    locale: null,
    relative: {
      round: true,
      unit: null
    }
  }
});

Vue.use(VNumeric);


Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
});

Vue.use(DisableAutocomplete);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);
/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
