import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";

Vue.use(Router)

export const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/StudentLayout2.vue'),
    beforeEnter(to, from, next){
      if(store.getters["app/isAuthenticated"] === false){
        return next({name:'login'})
      }
      return next();
    },
    children: [
      {
      path: '',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/service/StudentDashboard.vue')
    },
      {
        path: 'service',
        name: 'service',
        component: () => import(/* webpackChunkName: "add-service" */ '@/pages/service/Service')
      },
      {
        path: 'summer-project',
        name: 'summer-project',
        component: () => import(/* webpackChunkName: "add-service" */ '@/pages/SummerProject')
      },
      {
        path: 'add-service',
        name: 'add-service',
        component: () => import(/* webpackChunkName: "add-service" */ '@/pages/service/AddService.vue')
      },
      {
        path: 'modify-service/:id',
        name: 'modify-service',
        component: () => import(/* webpackChunkName: "modify-service" */ '@/pages/service/EditService.vue')
      },
      {
        path: 'documents',
        name: 'manage-documents',
        component: () => import(/* webpackChunkName: "modify-service" */ '@/pages/Documents.vue')
      }
    ]
  },

  {
    path: '/admin',
    name: 'admin',
    beforeEnter(to, from, next){
      if(store.getters["app/isAuthenticated"] === false || store.getters["app/isAdmin"] === false){
        return next({path:'/'})
      }
      return next();
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/layouts/AdminLayout.vue'),
    children: [
      {
      path: '',
      component: () => import(/* webpackChunkName: "admin" */ '@/pages/BlankPage')
    },
      {
        path: 'students',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/StudentRoster')
      },
      {
        path: 'student-bulk-load',
        name:'student-bulk-load',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/StudentBulkLoad')
      },
      {
        path: 'modify-service/:id',
        name:'admin-modify-service',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/service/EditService')
      },
      {
        path: 'modify-student/:id',
        name:'modify-student',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/EditStudent')
      },
      {
        path: 'create-student',
        name:'create-student',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/CreateStudent')
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Categories')
      },
      {
        path: 'modify-category/:id',
        name:'modify-category',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/EditCategory')
      },
      {
        path: 'create-category',
        name:'create-category',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/CreateCategory')
      },
      {
        path: 'organizations',
        name: 'organizations',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Organizations')
      },
      {
        path: 'modify-organization/:id',
        name:'modify-organization',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/EditOrganization')
      },
      {
        path: 'create-organization',
        name:'create-organization',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/CreateOrganization')
      },
      {
        path: 'admins',
        name: 'admins',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/Admins')
      },
      {
        path: 'modify-admin/:id',
        name:'modify-admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/EditAdmin')
      },
      {
        path: 'create-admin',
        name:'create-admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/CreateAdmin')
      },
      ]
  },

  {
    path: '/reports',
    name: 'reports',
    beforeEnter(to, from, next){
      if(store.getters["app/isAuthenticated"] === false || store.getters["app/isAdmin"] === false){
        return next({path:'/'})
      }
      return next();
    },
    component: () => import(/* webpackChunkName: "admin" */ '@/layouts/AdminLayout.vue'),
    children: [
      {
        path: 'student-service-summary',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/reports/StudentServiceSummaryReport')
      },
      {
        path: 'service-analysis',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/reports/ServiceAnalysis')
      },
      {
        path: 'monthly-service',
        component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/reports/MonthlyServiceReport')
      },
    ]
  },


  {
    path: '/change-password',
    beforeEnter(to, from, next){
      if(store.getters["app/isAuthenticated"] === false){
        return next({name:'login'})
      }
      return next();
    },
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/AccountLayout.vue'),
    children: [{
      path: '',
      name: 'change-password',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/ChangePassword')
    }]
  },

  {
    path: '/login',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/AccountLayout.vue'),
    children: [{
      path: '',
      name: 'login',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/login')
    }]
  },

  {
    path: '/forgot-password',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/AccountLayout.vue'),
    children: [{
      path: '',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/ForgotPassword')
    }]
  },

  {
    path: '/reset-password/:id',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/AccountLayout.vue'),
    children: [{
      path: '',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/ResetPassword')
    }]
  },

  {
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */

/*
router.beforeEach((to, from, next) => {


})
*/
/**
 * After each route update
 */

/*
router.afterEach((to, from) => {
})
*/
export default router
