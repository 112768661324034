import axios from 'axios';
import store from '@/store';
import router from '@/router';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + '/api',
  headers: {
    "Content-Type": "application/json",
    // anything you want to add to the headers
  },
  withCredentials: true
});

//httpClient.defaults.withCredentials = true

httpClient.interceptors.response.use(function(response){
  return response;
}, function(error){
  console.log(error.response.data);
  if(error.response.status === 401){
    console.log('!');
    store.dispatch('app/logout');
    router.push('/login');
  }

  return Promise.reject(error);
});

export default httpClient;
